<template>
  <div>  
    <b-row class="justify-content-center"> 
         <b-col cols="10" md="10" :style="isMobile ? '' : 'margin-top: -50px;'">
          <center> <img src="elementos/jonrronero.png" class="rounded" alt="Image" :width="isMobile ? 220 : '330'"></center> 
          <br> <br> 
          <h2 class="text-white text-center">Encuesta de satisfacción</h2>
          <b-row class="justify-content-center mt-5" > 
                  <b-col cols="9" md="9" lg="4" xl="4" class="mt-5">
                    <center><img src="elementos/descargar.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:60%; cursor:pointer;'" @click="descargaReporte()"><b-spinner v-if="loader" small></b-spinner>  </center> 
                  </b-col> 
              </b-row>
              <br>
         
         </b-col> 
    </b-row>   
  </div>
</template>

<script>
  // import Multiselect from 'vue-multiselect'
// import $ from "jquery";
// import InitController from "@/utils/InitController";  
// import Swal from 'sweetalert2'
// Import Bootstrap and BootstrapVue CSS files (order is important) 
export default {
  name: 'encuesta-admin',
  //  components: { Multiselect },
  data(){
    return{
      isMobile: this.$mobile(), 
      loader:false
    }
  }, 
  methods:{
    descargaReporte(){
        this.loader = true;
          this.$fileDownload(
            this.$api.baseUri + 'auth/reporteconvencion',  "reporte-encuesta-convencion_" + this.$getDate() + ".xlsx",
            ).then(
                () => {
                    this.loader = false; 
                }
            ) 
    }
  },
  mounted(){
    //  window.location.href = "https://convencioncomercial.com.mx/encuesta/encuesta.jpg";   
    this.$bus.$emit("cambia_embajador", true)

if(!this.isMobile)
  {
      this.$bus.$emit("fondo_capa", "fondoSolo")
  } 
   if (this.isMobile)
    {
      this.$bus.$emit("cambi_fo2", true)
    } 
  }
}
</script>  
<style>
 .form-check-input[type=radio] {
    width: 1.5rem;
    height: 1.5rem;
    border-color: black;
 }
</style>